import { Injectable, OnDestroy } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { LoggedInUser } from '../../auth/store';
import { Store } from '@ngxs/store';
import { debounceTime } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { UserRole } from '../../../generated/graphql';

@Injectable()
export class AnalyticsService implements OnDestroy {
  private readonly teacherLessonsSearchSubject = new Subject<string>();
  private teacherLessonsSearchSubscription: Subscription;

  constructor(
    private gtmService: GoogleTagManagerService,
    private store: Store
  ) {
    this.teacherLessonsSearchSubscription = this.teacherLessonsSearchSubject.pipe(debounceTime(1000)).subscribe((searchTerm) => {
      this.gtmService.pushTag({ event: 'pg-lessons-search', searchTerm });
    });
  }

  ngOnDestroy() {
    this.teacherLessonsSearchSubscription?.unsubscribe();
  }

  public pushRouteChangeEvent() {
    const user = this.store.selectSnapshot<LoggedInUser>((state) => state.loggedInUser?.user);
    const userId = user?._id;
    const userRole = user?.role?.toString();
    const eduProfile = user?.eduProfile;
    const grade = user?.grade;
    const email = userRole !== UserRole.Student ? user?.email : 'STUDENT';

    console.log('Pushing user data to data layer: ' + userId + ' with role: ' + userRole);

    this.gtmService.pushTag({
      email,
      userId: userId,
      role: userRole,
      district: user?.group?.name,
      state: user?.group?.address?.state,
      gradesTaught: eduProfile?.gradesTaught || [],
      grade: grade || [],
    });
  }

  public pushTeacherLessonsSearchEvent(searchTerm: string) {
    this.teacherLessonsSearchSubject.next(searchTerm);
  }
}
